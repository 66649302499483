import { handleErrorWithSentry, init as initSvelteSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/capacitor';
import { dev } from '$app/environment';

Sentry.init(
	{
		enabled: !dev || import.meta.env.VITE_SENTRY_ENABLED === 'true',
		dsn: 'https://726e9cfef29546168363fd86c322a618@o1399430.ingest.sentry.io/4505386871750656',
		tracesSampleRate: 1.0
	},
	initSvelteSentry
);

export const handleError = handleErrorWithSentry();
