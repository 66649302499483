import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/login": [7],
		"/project/[id]": [8,[2]],
		"/project/[id]/calculation": [9,[2,3]],
		"/project/[id]/calculation/configurator": [10,[2,3]],
		"/project/[id]/calculation/gaeb": [11,[2,3]],
		"/project/[id]/debug": [12,[2]],
		"/project/[id]/design": [13,[2,4]],
		"/project/[id]/design/separations": [14,[2,4]],
		"/project/[id]/design/surfaces": [15,[2,4]],
		"/project/[id]/design/visualization": [16,[2,4]],
		"/project/[id]/details": [17,[2]],
		"/project/[id]/preparation": [18,[2,5]],
		"/project/[id]/preparation/camera": [19],
		"/project/[id]/preparation/dimensions": [20,[2,5]],
		"/project/[id]/preparation/facades": [21,[2,5]],
		"/project/[id]/preparation/insulation": [22,[2,5]],
		"/project/[id]/preparation/openings": [23,[2,5]],
		"/project/[id]/requirements": [24,[2]],
		"/project/[id]/variants": [25,[2]],
		"/register": [26],
		"/terms": [27],
		"/user-settings": [28]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';